@import "../../../../../themes/Theme";

.shadowContainer {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  @include xs() {
    padding: 17px 5px;
  }
  .csText {
    font-size: 10px;
    text-align: center;
  }
}
.secondChartContainer {
  p {
    position: absolute;
    top: 41%;
    left: calc(50% - 13px);
    font-size: 13px;
    font-weight: 600;
  }
  height: 70px;
  padding: 15px 0px 15px 0px;
  position: relative;
}

