@import "../../../../../themes/Theme";

.shadowContainer {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  @include xs() {
    padding: 17px 5px;
  }
}
.stFirstRank {
  padding: 17px;
  @include xs() {
    display: none;
  }
}
.nmTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin: 17px 0 15px;
}
.nmBox {
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    margin-bottom: 8px;
  }
  p {
    font-size: 10px;
    text-align: center;
  }
}
.mnBoxFirst {
  &:after {
    top: 16px;
    right: -16;
    width: 24;
    height: 24;
    content: "vs";
    position: absolute;
    background: #f5f6f7;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    font-family: "Poppins";
  }
  position: relative;
}
