@import "../../../../../themes/Theme";

.shadowContainer {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  @include xs() {
    padding: 17px 5px;
  }
}
.stSecondWeeks {
  padding-left: 16px;
  @include xs() {
    display: none;
  }
}
.gpPercent {
  font-size: 22px;
  font-weight: 700;
  margin-top: -14px;
  margin-left: 18px;
}
.cwStats {
  border-top: 1px solid #d6d9dc;
  margin-top: 15px;
  padding-top: 14px;
  font-size: 14px;
  color: #7c828a;
  @include xs() {
    font-size: 12px;
  }
}
