@import "../../../../../themes/Theme";

.shadowContainer {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  @include xs() {
    padding: 17px 5px;
  }
}
.stFirstRank {
  padding: 17px;
  @include xs() {
    display: none;
  }
}
.hrRank {
  font-size: 14px;
  text-align: center;
  @include xs() {
    font-size: 10px;
  }
}
.hrBox {
  div {
    display: flex;
    @include xs() {
      align-items: center;
    }
    svg {
      font-size: 25px;
      margin: 13px 5px 0 0;
      @include xs() {
        margin: unset;
      }
    }
    p {
      font-size: 34px;
      font-weight: 700;
      @include xs() {
        font-size: 20px;
      }
    }
  }
  .sameWeekTxt {
    font-size: 12px;
    margin-top: 6px;
    @include xs() {
      font-size: 8px;
    }
  }
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

