@import '../../themes/Theme';

.toolbar {
  width: 100%;
  min-height: 60px;
  // color: $COLOR_TEXT;
  @include xs(){
    // display: flex;
  }
}
.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.containerStyles {
  background-color: rgb(251, 251, 251);
  display: flex;
  flex-grow: 1;
}
.botMenu {
  span {
    font-weight: 500;
  }
  position: fixed;
  z-index: 9;
  bottom: 0;
  width: 100%;
  box-shadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)";
}
