@import "../../../../themes/Theme.scss";

.topStatThird {
  width: 28%;
  @include xs() {
    display: "none";
  } 
}
.mainComp {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 12px;
  background-color: #fff;
}
.header {
  p {
    color: #49495c;
    font-size: 16px;
    font-weight: 700;
  }
  border-bottom: 1px solid #e9e8e9;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.body {
  padding-bottom: 30px;
  @include xs() {
    padding-bottom: 50px;
  }
}
