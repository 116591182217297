@import "../../../../themes/Theme.scss";

.topStatSecond {
  width: 32%;
  padding: 0 50px;
  @include xs() {
    width: 50%;
    padding: 0 8px 0 0;
  }
}
.mainComp {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 12px;
  background-color: #fff;
}
.header {
  p {
    color: #49495c;
    font-size: 16px;
    font-weight: 700;
  }
  border-bottom: 1px solid #e9e8e9;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.body {
  padding: 16px 0;
  overflow-y: auto;
  flex-flow: column;
  @include xs() {
    padding: 0 16px;
  }
}
