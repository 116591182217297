@import "../../../../../themes/Theme";

.shadowContainer {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  @include xs() {
    padding: 17px 5px;
  }
}