@use "sass:list";

// ##############################################
// Colors
// ##############################################

//Text

$COLOR_TEXT: #333333;

// ##############################################
// Layout
// ##############################################

// Content max width
$MAX_WIDTH: var(--max-width, 1600px) !default;

// Breakpoints
$BREAKPOINT_XS: 576px !default;
$BREAKPOINT_SM: 768px !default;
$BREAKPOINT_MD: 992px !default;
$BREAKPOINT_LG: 1200px !default;
$BREAKPOINT_XL: $MAX_WIDTH;

// ##############################################
// Mixins
// ##############################################
@import 'mixins/Breakpoints'